import HomePage from './Container/HomePage';
import React from "react";

function App() {
  return (
    <>
    <HomePage />
    </>
  );
}

export default App;
